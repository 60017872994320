.board-photo {
  margin-left: 20px;
  margin-right: 20px;
}
.board {
  margin-top: 50px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.board-details {
  display: flex;
  flex-flow: column;
  min-width: 200px;
}
.board-info {
  font-weight: bold;
}
.board-bio {
  font-weight: normal;
}
@media all and (min-width: 800px) {
  .board {
    flex-wrap: nowrap;
  }
}
