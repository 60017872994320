.banner-container {
  position: relative;
  margin-top: 0;
  background-repeat: no-repeat;
  background-position: left middle;
}
.banner-text-overlay {
  margin-top: 0;
  margin-right: 50px;
  margin-left: auto;
  max-width: 800px;
  padding: 20px;
  background-color: rgba(133, 106, 133, 0.9);
}
h1 {
  font-size: 30px;
  font-family: 'Oxygen', cursive;
  font-weight: bold;
}
h1.banner-title {
  margin-bottom: 15px;
  width: auto;
  color: #e8f857;
  line-height: 1;
}
.banner-subtitle {
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
  font-family: 'Oxygen', cursive;
}
