h1.section-title {
  color: #46a0db;
  text-align: center;
  margin-top: 50px;
}
.section-description {
  font-style: italic;
  font-weight: bold;
  margin: auto;
  width: fit-content;
  max-width: 85%;
}
