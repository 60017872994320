.sponsors-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.sponsorship-information {
  display: block;
  margin: auto;
  width: fit-content;
  max-width: 80%;
}
h2 {
  color: maroon;
}
