.concert-list {
  display: block;
}
.concert {
  display: flex;
  margin: 25px auto;
  width: fit-content;
  align-items: center;
  justify-content: center;
}
.concert-brief {
  display: block;
  width: fit-content;
  font-weight: bold;
  font-size: larger;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0 auto;
}
.concert-buttons {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
button.ticket-link {
  background-color: green;
}
button.program-link {
  background-color: slateblue;
}
button {
  cursor: pointer;
}
a {
  cursor: pointer;
}
