h1.regular-title {
  color: #46a0db;
  text-align: center;
}
.member-list {
  display: flex;
  flex-wrap: wrap;
}
.member-list-key {
  font-style: italic;
  font-size: smaller;
}
