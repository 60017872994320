@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap');

/* index.css */
body {
  border: 0;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.7;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
button {
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  font-size: 1em;
  display: inline-block;
  padding: 10px 30px;
  border-width: 0;
  background-color: #14a839;
  background-image: none;
  text-shadow: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  line-height: 1.6;
}
.row {
  margin: 0 auto;
  padding: 0 25px;
  max-width: 1200px;
  margin-bottom: 15px;
}
a:link {
  color: #5087ca;
  text-decoration: none;
}
a:hover {
  color: #406ea7;
  text-decoration: none;
}
.hidden {
  display: none;
}
.form-input-label {
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
}
.center-page {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: fit-content;
}
.vertical-space-20 {
  margin-top: 20px;
}
.indent {
  margin-left: 100px;
}
.expand-left {
  display: grid;
  grid-template-columns: auto 100px;
}
.left {
  margin-left: 0;
  margin-right: auto;
  width: fit-content;
}
.right {
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}
.right-align-items {
  display: block;
  text-align: right;
}
