.sponsor {
  height: 150px;
  width: 250px;
  margin: 25px;
  overflow: hidden;
}
.sponsor img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
