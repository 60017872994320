a.menu-button {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 2px none #fff;
  border-top-left-radius: 0 0;
  border-top-right-radius: 0 0;
  border-bottom-right-radius: 0 0;
  border-bottom-left-radius: 0 0;
  background-color: transparent;
  color: #edf6be;
  text-transform: none;
  letter-spacing: 0;
  font-family: 'Oxygen', cursive;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  width: auto;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-width: 0;
  line-height: 1.7;
  vertical-align: baseline;
  padding: 10px 15px;
}
a.menu-button:hover {
  border-bottom-width: 2px;
  border-bottom-color: #42adff;
  background-color: #424242;
  color: #fff;
}
