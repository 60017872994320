.ticket-list {
  display: block;
}
.ticket {
  display: block;
  width: fit-content;
}
.ticket-name {
  font-weight: bold;
  font-size: larger;
}
.ticket-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
label.money {
  width: 50px;
  text-align: right;
}
input.ticket-quantity {
  width: 50px;
  text-align: right;
}
.two-column {
  display: grid;
  grid-template-columns: auto auto;
  align-items: end;
}
