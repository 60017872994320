.menubar {
  display: flex;
  top: 0;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 0;
  width: 100%;
  background-color: maroon;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;
  position: sticky;
}
img.logo {
  position: relative;
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  background-color: white;
  filter: drop-shadow(5px 5px 10px black);
  z-index: 2;
}
.menu-buttons-container {
  margin-right: 50px;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
}
