footer {
  display: flex;
  padding: 0 20px;
  margin: 0;
  max-width: none;
  background-color: maroon;
  color: #fff;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7;
  flex-wrap: wrap;
}
h4.footer-heading {
  margin-bottom: 0;
  padding-right: 0;
  padding-bottom: 5px;
  width: 100%;
  color: #edf6be;
  text-align: left;
  font-size: 24px;
  font-family: Oxygen, cursive;
  font-weight: bold;
}
.footer-column {
  width: 25%;
  min-width: 200px;
  flex-wrap: wrap;
}
.footer-rule {
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.footer-rule hr {
  margin: 0;
  border: 0;
  border-top: 1px solid #ddd;
  width: 100%;
  border-top-color: #669ad1;
}
a.social-image {
  margin-right: 4%;
  min-width: 0;
  max-width: 50px;
  background-color: #426dc2;
  border-radius: 3px;
  max-width: 50px;
  display: inline-block;
}
.social-image img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
